module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Evan JM Davies","icon":"src/images/favicon.webp","start_url":"https://evanjmdavies.com/","display":"standalone","background_color":"#333333","theme_color":"#c42034","scope":"https://evanjmdavies.com/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"064a4aea4c3be246a96924b160016516"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
